.header-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 0px;
}

.header-image-darker{ 
    filter: brightness(50%);
}

.header-image-brighter{
  filter: brightness(120%);
}

.descriptionHeader {
  font-weight: bold;
  z-index: 0;
}
