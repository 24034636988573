.sun {
    position: absolute;
    top: 40px;
    left: 100px;
    bottom: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: white;
    opacity: 0.9;
    box-shadow: 0px 0px 40px 15px white;
}

.ray_box {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0;
    width: 70px;
    -webkit-animation: ray_anim 120s linear infinite;
    animation: ray_anim 120s linear infinite;
    z-index: 0;
}
.ray {
    background-color: whitesmoke;
    background: whitesmoke;
    /* background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0) 100%
    ); */
    margin-left: 10px;
    border-radius: 80% 80% 0 0;
    position: absolute;
    opacity: 0.1;
    z-index: 0;
}

.ray1 {    
    height:170px;
    width:30px;
   -webkit-transform: rotate(180deg);
    top:-175px;
    left: 15px;
}
.ray2 {
    height:100px;
    width:8px;
   -webkit-transform: rotate(220deg);
    top:-90px;
    left: 75px;
}
.ray3 {
    height:170px;
    width:50px;
   -webkit-transform: rotate(250deg);
    top:-80px;
    left: 100px;
}
.ray4 {
    height:120px;
    width:14px;
   -webkit-transform: rotate(305deg);
    top:30px;
    left: 100px;
}
.ray5 {
    height:140px;
    width:30px;
   -webkit-transform: rotate(-15deg);
    top:60px;
    left: 40px;
}
.ray6 {
    height:90px;
    width:50px;
   -webkit-transform: rotate(30deg);
    top:60px;
    left: -40px;
}
.ray7 {
    height:180px;
    width:10px;
   -webkit-transform: rotate(70deg);
    top:-35px;
    left: -40px;
}
.ray8 {
    height:120px;
    width:30px;
   -webkit-transform: rotate(100deg);
    top:-45px;
    left:-90px;
}
.ray9 {
    height:80px;
    width:10px;
   -webkit-transform: rotate(120deg);
    top:-65px;
    left:-60px;
}
.ray10 {
    height:190px;
    width:23px;
   -webkit-transform: rotate(150deg);
    top:-185px;
    left: -60px;
}

@keyframes ray_anim {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
