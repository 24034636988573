
.rain {
    position: absolute;
    display: none;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    background: linear-gradient(to bottom, #202020, #111119);
  }
  
  .rain.back-row {
    display: none;
    z-index: 9999;
    bottom: 60px;
    opacity: 0.5;
  }
  
  .drop {
    position: absolute;
    bottom: 100%;
    width: 15px;
    height: 120px;
    pointer-events: none;
    animation: drop 0.5s linear infinite;
  }
  
  @keyframes drop {
    0% {
      transform: translateY(0vh);
    }
    75% {
      transform: translateY(120vh);
    }
    100% {
      transform: translateY(120vh);
    }
  }
  
  .stem {
    width: 1px;
    height: 60%;
    margin-left: 7px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.25)
    );
    animation: stem 0.5s linear infinite;
  }
  
  @keyframes stem {
    0% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }